




























































































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: {
        appid: [{ required: true, message: '请填写APP ID' }],
        code: [{ required: true, message: '请填写商品代码' }],
        cn_name: [{ required: true, message: '请填写商品中文名' }],
        keywords: [{ required: true, message: '请填写商品关键词' }],
      },
      model: {
        appid: '',
        code: '',
        link: '',
        discount: null,
        cn_name: '',
        en_name: '',
        version: '',
        tags: '',
        thumbnail: '',
        description: '',
        keywords: '',
        price_plus: '',
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      this.formLoading = true
      const { data: configs } = await this.$http.get(`goods/${this.id}`)
      this.model = Object.assign(this.model, configs)
      this.formLoading = false
    },
    async save() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true
          if (this.id) {
            await this.$http.put(`goods/${this.id}`, this.model)
          } else {
            await this.$http.post('goods', this.model)
          }
          this.loading = false
          this.$router.push('/good/list')
          this.$message.success('保存成功')
        }
      })
    },
  },
})
